import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteDraft, getDraftByContractor } from "../../services/drafts";
import { Badge, Checkbox, Flex, Tag } from "antd";
import { message, removePrefix, statuses } from "../../utils/utils";
import {
  DownOutlined,
  EditOutlined,
  ProjectOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { getDraftTypeLabel } from "../../utils/drafts.js";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/slices/userSlice.js";
import { apiByRole } from "../../utils/apiByRole.js";
import SearchInput from "../../components/inputs/SearchInput";
import Status from "../../components/status/index.js";
import SolarRequest from "../../components/createDraftForm/SolarRequest.js";
import InfiniteScrollCards from "../../components/infiniteScrollCards/index.js";
import IconWithText from "../../components/iconWithText/index.js";
import CreateDraft from "../../components/createDraft/index.js";
import CustomDrawer from "../../components/drawer/index.js";
import FiltersForm from "./components/FiltersForm.js";
import isEmpty from "lodash/isEmpty.js";
import get from "lodash/get.js";
import "./styles.css";

const ProjectsMobile = ({ submitted = true }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get("search") || "";
  const status = searchParams.get("status") || "";
  const hasNewRevisions = searchParams.get("hasNewRevision") || "";
  const [drafts, setDrafts] = useState({ items: [], count: 0 });
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(query);
  const [selectedStatus, setSelectedStatus] = useState(status);
  const [activeItem, setActiveItem] = useState("");
  const [draftModalOpen, setDraftModalOpen] = useState(false);
  const [selectedDraft, setSelectedDraft] = useState({});
  const [hasNewRevision, setHasNewRevision] = useState(hasNewRevisions);
  const [filterModalOpen, setFilterModalOpen] = useState(false);

  const { role } = useSelector(selectUser);
  const isAdmin = role === "admin";
  const navigate = useNavigate();

  const fetchRequest = apiByRole[role].projects.getDrafts;

  const fetchData = () => {
    setLoading(true);
    fetchRequest({
      offset: 0,
      limit: 25,
      searchQuery,
      status: selectedStatus,
      submitted,
      hasNewRevision,
    })
      .then(setDrafts)
      .catch((error) =>
        console.error("GET-ActiveContractors - CATCH --- ERROR", error)
      )
      .finally(() => setLoading(false));
  };
  const fetchMoreData = () => {
    setLoading(true);
    fetchRequest({
      offset: drafts.items.length,
      limit: 25,
      searchQuery,
      status: selectedStatus,
      submitted,
      hasNewRevision,
    })
      .then((data) =>
        setDrafts({
          count: data.count,
          items: [...drafts.items, ...data.items],
        })
      )
      .catch((error) => console.error("GET-Drafts - CATCH --- ERROR", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!!searchQuery) {
      const timeoutId = setTimeout(fetchData, 500);
      return () => clearTimeout(timeoutId);
    } else {
      fetchData();
    }
  }, [searchQuery, selectedStatus, submitted, hasNewRevision]);

  useEffect(() => {
    const targetDiv = document.getElementById(activeItem);
    if (targetDiv) {
      targetDiv.scrollIntoView({ behavior: "smooth" });
    }
  }, [activeItem]);

  const handleResetFilters = () => {
    setSelectedStatus("");
    setHasNewRevision(false);
    searchParams.delete("status");
    searchParams.delete("hasNewRevision");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const onFilterChange = ({ status = "", newRevisionChecked }) => {
    if (status) {
      setSelectedStatus(status);
      searchParams.set("status", status);
    }
    if (newRevisionChecked !== undefined) {
      setHasNewRevision(newRevisionChecked);
      searchParams.set("hasNewRevision", newRevisionChecked);
    }
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  const handleSearch = (value) => {
    setSearchQuery(value);
    value ? searchParams.set("search", value) : searchParams.delete("search");
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };

  const onEditClick = (item) => {
    getDraftByContractor(item.id)
      .then((res) => {
        let newData = {
          ...res,
          ...get(res, "electricalData", {}),
          ...get(res, "solarData", {}),
          projectName: res.name,
          phoneNumberNotRequired: res.phone,
        };
        if (!isEmpty(newData.clientAttachments)) {
          newData.clientAttachments = newData.clientAttachments.map((file) => {
            return {
              ...file,
              dbName: file.fileName,
              name: removePrefix(file.fileName),
            };
          });
        }
        if (res.solarData) {
          newData.batteryStorage = res.solarData.batteryStorage ? "Yes" : "No";
          newData.note = res.solarData.note;
        }
        if (res.electricalData) {
          newData.acDisconnect = newData.acDisconnect ? "Yes" : "No";
          newData.pvProductionMeter = newData.pvProductionMeter ? "Yes" : "No";
          newData.mainElectricalPanelUpgrade =
            newData.mainElectricalPanelUpgrade ? "Yes" : "No";
          newData.electricalNote = res.electricalData.note;
          if (!isEmpty(newData.subpanelNew)) {
            newData.newBusRating = newData.subpanelNew.busRating;
            newData.newBreakerRating = newData.subpanelNew.breakerRating;
          }
          if (!isEmpty(newData.subpanelExisting)) {
            newData.existingBusRating = newData.subpanelExisting.busRating;
            newData.existingBreakerRating =
              newData.subpanelExisting.breakerRating;
          }
        }
        setSelectedDraft(newData);
      })
      .finally(() => {
        setDraftModalOpen(true);
      });
  };

  const onSubmit = () => {
    if (!isEmpty(selectedDraft) && !submitted) {
      setDrafts({
        count: drafts.count - 1,
        items: drafts.items.filter((draft) => selectedDraft.id !== draft.id),
      });
    }
    message({
      type: "success",
      message: "Your request has been successfully sent!",
    });
  };
  const onViewProject = (project) => {
    navigate(`${project.id}/details`);
  };

  const onDeleteDraft = (id) => {
    deleteDraft(id).then(() =>
      setDrafts((prev) => {
        return {
          count: prev.count - 1,
          items: prev.items.filter((f) => f.id !== id),
        };
      })
    );
  };

  const renderActions = (item) => {
    const actions = [
      <div
        className="is-link"
        onClick={
          !submitted ? () => onEditClick(item) : () => onViewProject(item)
        }
      >
        <IconWithText
          text={!item.status ? "Edit" : "View"}
          IconComponent={!item.status ? EditOutlined : ProjectOutlined}
          iconClassname="text-xl"
          styles={{ justifyContent: "center" }}
        />
      </div>,
    ];
    if (!submitted) {
      actions.push(
        <div
          className="cursor-pointer text-error"
          onClick={() => onDeleteDraft(item.id)}
        >
          <IconWithText
            text="Delete"
            IconComponent={DeleteOutlined}
            iconClassname="text-xl"
            styles={{ justifyContent: "center" }}
          />
        </div>
      );
    }
    if (submitted && actions.length > 1) {
      actions.splice(-1);
    }
    return actions;
  };

  const onFilterFinish = ({ status }) => {
    setFilterModalOpen(false);
    onFilterChange({ status });
  };

  const filterOptions = statuses[role].project;

  const submittedFields = {
    name: {
      icon: ProjectOutlined,
    },
    draftTypes: {
      label: "Service Type",
      render: ({ draftTypes }) => {
        return <p>{getDraftTypeLabel(draftTypes)}</p>;
      },
    },
    companyName: {
      label: "Company",
      url: ({ userId }) => `/contractors/${userId}`,
      invisible: !isAdmin,
    },
    createdAt: {
      label: "Created Date",
      isDate: true,
    },
    totalPrice: {
      label: "Price",
    },
    revisions: {
      label: "Revisions",
      render: ({ revisions, newRevisions = 0 }) => (
        <Badge count={newRevisions} color="#4D6EF2">
          <Tag className="revision-tag">{revisions}</Tag>
        </Badge>
      ),
    },
    status: {
      label: "Status",
      render: ({ status }) => {
        return <Status status={status} currentOptions={filterOptions} />;
      },
    },
  };
  const fields = {
    name: {
      icon: ProjectOutlined,
    },
    address: {
      label: "Address",
    },
    draftTypes: {
      label: "Service Type",
      render: ({ draftTypes }) => {
        return <p>{getDraftTypeLabel(draftTypes)}</p>;
      },
    },
    createdAt: {
      label: "Created",
      isDate: true,
    },
  };
  const renderFormTitle = () => {
    const draftTypes = Object.keys(selectedDraft.draftTypes || [])
      .map((key) => {
        if (selectedDraft.draftTypes[key]) {
          return key;
        }
      })
      .filter(Boolean);
    if (draftTypes.includes("solar")) {
      return "Solar Engineering  Request";
    }
    if (draftTypes.length === 2) {
      return "Electrical & Structural  Engineering Request";
    }
    if (draftTypes[0] === "electrical") {
      return "Electrical Engineering Request";
    }
    return "Structural  Engineering Request";
  };
  return (
    <div>
      <Flex gap={24} vertical className="margin-top-xxxl margin-bottom-xl">
        <SearchInput
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {(submitted || isAdmin) && (
          <Flex
            className={!isAdmin ? "row-reverse" : ""}
            gap={12}
            align="center"
            justify={!isAdmin ? "space-between" : "flex-start"}
          >
            {!isAdmin && <CreateDraft />}
            <Flex gap={12} align="center">
              {isAdmin && submitted && (
                <Checkbox
                  className="text-gray"
                  onChange={(e) =>
                    onFilterChange({ newRevisionChecked: e.target.checked })
                  }
                  checked={hasNewRevision}
                >
                  New Revisions
                </Checkbox>
              )}
              <div className="filter-badge-wrapper cursor-pointer">
                {!!selectedStatus && (
                  <Badge dot color="#4D6EF2" className="filter-badge" />
                )}
                <div onClick={() => setFilterModalOpen(true)}>
                  <IconWithText
                    customClass="text-gray"
                    text="Status"
                    IconComponent={DownOutlined}
                    styles={{
                      flexDirection: "row-reverse",
                    }}
                  />
                </div>
              </div>
              {(selectedStatus || hasNewRevision) && (
                <p className="is-link" onClick={handleResetFilters}>
                  Clear Filters
                </p>
              )}
            </Flex>
          </Flex>
        )}
      </Flex>
      <InfiniteScrollCards
        data={drafts}
        fetchMoreData={fetchMoreData}
        fields={submitted ? submittedFields : fields}
        loading={loading}
        renderActions={renderActions}
      />
      <CustomDrawer
        open={filterModalOpen}
        onCancel={() => setFilterModalOpen(false)}
        title="Status"
        content={
          <FiltersForm
            onFilterFinish={onFilterFinish}
            onCancel={() => setFilterModalOpen(false)}
            initialValues={{ status: selectedStatus }}
            filterOptions={filterOptions}
          />
        }
      />
      <CustomDrawer
        open={draftModalOpen}
        title={renderFormTitle()}
        content={
          <SolarRequest
            selectedDraft={selectedDraft}
            onSubmit={onSubmit}
            onCancel={() => setDraftModalOpen(false)}
            selectedTypes={
              !isEmpty(selectedDraft) &&
              Object.keys(selectedDraft.draftTypes)
                .map((item) => selectedDraft.draftTypes[item] && item)
                .filter(Boolean)
            }
          />
        }
        onCancel={() => setDraftModalOpen(false)}
      />
    </div>
  );
};

export default memo(ProjectsMobile);
